import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ApiService from "../../../Services/ApiService";
import { Loader } from "../../Loader";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
const PackageEdit = ({ onCancel, dataSource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState();
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const [selectedNetworkChannels, setSelectedNetworkChannels] = useState({});
  const handleChangesnetwork = (networkGenreId, value, genreName) => {
    setSelectedNetworkChannels((prevSelectedChannels) => ({
      ...prevSelectedChannels,
      [networkGenreId]: value,
    }));
  };
  const formRef = useRef(null);
  const [OffersDays, setOffersDays] = React.useState([]);
  const [typeArray, setType] = useState([]);
  const [value, setValue] = React.useState([]);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isNetworkSelected, setIsNetworkSelected] = useState(false);
  const [isAddonsSelected, setIsAddonsSelected] = useState(false);
  const [networkvalue, setnetworkValue] = React.useState([]);
  const [NetworkArray, setNetwork] = useState([]);
  const [isTypeSelectedNetwork, setIsTypeSelectedNetwork] = useState(false);
  const [languageArray, setLanguage] = useState([]);
  const [languagevalue, setlanguageValue] = React.useState(0);
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [BoxtypeArray, setBoxtype] = useState([]);
  const [ListArray, setListArray] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [NetworkAndGenre, setListNetworkGenre] = useState([]);
  const [PackageName, setPackageName] = useState("");
  const [SystemName, setSystemName] = useState("");
  const [Poid, setPoid] = useState("");
  const [Description, setDescription] = useState("");
  const [PackageSmsCode, setPackageSmsCode] = useState("");
  const [IsNewUser, setIsNewUser] = useState("");
  const [PackageTypeValue, setPackageTypeValue] = useState("");
  const [IsExistingUser, setIsExistingUser] = useState("");
  const [IsOfferPack, setIsOfferPack] = useState(false);
  const [inactiveDays, setinactiveDays] = useState("0-30");
  const [IncludeNCF, setIncludeNCF] = useState("");
  const [NewUserMonthlyPrice, setNewUserMonthlyPrice] = useState("");
  const [ExistingUserMonthlyPrice, setExistingUserMonthlyPrice] = useState("");
  const [isActive, setIsActive] = useState("Active");
  const [PackageStatus, setPackageStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();
  const [ApprovedBy, setApprovedBy] = useState();
  const [ApprovedOn, setApprovedOn] = useState();
  const [DayFrom, setDayFrom] = useState();
  const [DayTo, setDayTo] = useState();
  const [IsPublished, setIsPublished] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [inputGroupExisting, setInputGroupsExisting] = useState([]);
  const [inputGroupOffer, setInputGroupsOffer] = useState([]);

  const [ChannelArray, setChannel] = useState([]);
  const [Channelvalue, setChannelValue] = React.useState([]);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const InactiveDays = [
    { value: "0-30", viewValue: "0-30 Days" },
    { value: "31-60", viewValue: "31-60 Days" },
    { value: "61-90", viewValue: "61-90 Days" },
    { value: "90+", viewValue: "90+ Days" },
  ];
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [isPublished, setIsPublised] = useState(true);
  const [postedName, setPostedName] = useState();
  useEffect(() => {
    // getEditDetails(rowData);

    setUserDetails(JSON.parse(localStorage.getItem("userDetail")));
    getLanguageList();
    getInactiveDaysList()
    getChannelList();
    getTypeList();
    // getNetworkList();
    getBoxTypeList();
    getGenreList();
    getData();
  }, [dataSource]);
  const [editData, setEditData] = useState(); // Add this line
  useEffect(() => {
    // This effect runs whenever editData changes
    // getUserName();
    if (editData) {
      const transformedData = editData.genreDetails.flatMap(
        ({ GenreId, GenreName, channelsList }) =>
          channelsList.map(({ ChannelId, ChannelName }) => ({
            ChannelId,
            GenreId,
            GenreName,
            ChannelName,
          }))
      );
      setChannelValue(transformedData);
    }
  }, [editData]); // Add editData as dependency
  useEffect(() => {
  
  }, [dataSource]);
  const getData = async () => {
    setInputGroupsExisting([]);
    setInputGroups([]);
    try {
      const res = await ApiService.getOne("sdmpackage", dataSource);
      if (res) {
        setPackageName(res.PackageName);
        setSystemName(res.SystemName);
        setPoid(res.Poid);
        setDescription(res.Description);
        setPackageStatus(
          res.PackageStatus == "Published" ? "approval" : res.PackageStatus
        );
        // setValue(res.packageTypeDetails);
        setPackageTypeValue(res.packageTypeDetails[0]?.PackageTypeId || []);
        setnetworkValue(res.networkDetails);
        setlanguageValue(Number(res.languageDetails[0]?.LanguageId));
        // console.log("........",res.languageDetails[0].LanguageId)
        setIsExistingUser(res.IsExistingUser);
        setIsOfferPack(res.IsOfferPack);
        // setIncludeNCF(res.IncludeNCF);
        setIsNewUser(res.IsNewUser);
        setNewUserMonthlyPrice(res.NewUserMonthlyPrice);
        setExistingUserMonthlyPrice(res.ExistingUserMonthlyPrice);
        setDayFrom(res.DayFrom);
        setDayTo(res.DayTo);
        setinactiveDays(
          res.DayFrom == 0 && res.DayTo == 30
            ? "0-30"
            : res.DayFrom == 31 && res.DayTo == 60
            ? "31-60"
            : res.DayFrom == 61 && res.DayTo == 90
            ? "61-90"
            : res.DayFrom == 90 && res.DayTo == 91
            ? "90+"
            : 0
        );
        if (res.newUserPriceDetails) {
          setInputGroups(
            (prevInputGroups) =>
              res.newUserPriceDetails.map((val) => ({
                input1: val.Description,
                input2: val.Price,
                input3: val.TotalGstPrice,
                input4: val.NcfPrice,
                input5: val.GstPrice,
                input6: val.ExcludeNcfPrice,
                input7: val.IsActive == true ? "Active" : "Inactive",
                input8: val.Poid,
                input9:
                  val.BoxTypeName === "OUTRIGHT"
                    ? "Outright"
                    : val.BoxTypeName === "RENTAL"
                    ? "Rental"
                    : val.BoxTypeName === "ENTRUSTMENT"
                    ? "Entrustment"
                    : val.BoxTypeName,
                input10: val.BoxId,
                input11: val.SystemName,    
              }))
            // .concat(prevInputGroups)
          );
        }
        if (res && res.existingUserPriceDetails) {
          setInputGroupsExisting(
            res.existingUserPriceDetails.map((val, index) => ({
              input1: val.Description,
              input2: val.Price,
              input3: val.TotalGstPrice,
              input4: val.NcfPrice,
              input5: val.GstPrice,
              input6: val.ExcludeNcfPrice,
              input7: val.Poid,
              input8: val.IsActive ? "Active" : "Inactive",
              input9: val.SystemName,
            }))
          );
        }
        if (res && res.existingUserOfferPriceDetails) {
          setInputGroupsOffer(
            res.existingUserOfferPriceDetails.map((val, index) => ({
              input1: val.Description,
              input2: val.Price,
              input3: val.TotalGstPrice,
              input4: val.NcfPrice,
              input5: val.GstPrice,
              input6: val.OfferPackDaysId,
              input7: val.Poid,
              input8: val.IsActive ? "Active" : "Inactive",
              input9:val.SystemName,
            }))
          );
        }

        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater(res.IsPublishLater);
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setApprovedBy(res.ApprovedBy);
        setApprovedOn(res.ApprovedOn);
        setIsPublished(res.IsPublished);
        setEditData(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const getTypeList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmpackagetype/list", typeRequest);
      if (res) {
        setType(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getNetworkList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmnetwork/list", typeRequest);
      if (res) {
        setNetwork(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getLanguageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmlanguage/list", typeRequest);
      if (res) {
        setLanguage(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getInactiveDaysList = async () => {
    try {
      const res = await ApiService.getAll("sdmpackage/offerpack/days");
      if (res) {
        setOffersDays(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getBoxTypeList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      const res = await ApiService.post("sdmboxtype/list", typeRequest);
      if (res) {
        setBoxtype(res.SdMBoxtype);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getGenreList = async () => {
    try {
      const res = await ApiService.getAll("sdmnetwork/genrewithchannels");
      if (res) {
        // console.log(res);
        setListArray(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  // const getUserName = async () => {
  //   try {
  //     const res = await ApiService.getOne("sdmusers", userDetails.Id);
  //     if (res) {
  //       // console.log(res);
  //       setPostedName(res.FirstName);
  //     }
  //   } catch (error) {
  //     console.error("Error updating toggle state:", error);
  //   }
  // };

  const handleApprovePublish = (e) => {
    e.preventDefault();
    if(PackageStatus != "approval"){
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (!IsNewUser && !IsExistingUser) {
      setcheckboxVali(true);
    } else {
      setLoading(true);
      const getpackageTypeId = [PackageTypeValue];
      const getLanguageId = [languagevalue ];
      // const getNetworkId = networkvalue.map((network) => network.Id);
      const newUserPriceDetails = inputGroups.map((group) => ({
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input8,
        IsActive: group.input7 === "Active",
        BoxTypeName: group.input9,
        BoxId: group.input10,
        SystemName: group.input11,
      }));
      const existingUserPriceDetails = inputGroupExisting.map((group) => ({
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input7,
        IsActive: group.input8 === "Active",
        // BoxTypeName: group.input9,
        SystemName:group.input9,
      }));
      const Offerpack = inputGroupOffer.map((group) => ({
        OfferPackDaysId:parseFloat(group.input6),
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        // ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input7,
        SystemName:group.input9,
        IsActive: group.input8 === "Active",
        // BoxTypeName: group.input9
      }));
      const transformedArray = Channelvalue.reduce((acc, curr) => {
        // Check if the genre exists in the accumulator array
        const existingGenre = acc.find((item) => item.GenreId === curr.GenreId);
        if (existingGenre) {
          // If the genre exists, add the channel to its NetworkGenreChannel array
          existingGenre.channelsList.push({
            ChannelId: curr.ChannelId,
          });
        } else {
          // If the genre doesn't exist, create a new entry for it in the accumulator array
          acc.push({
            GenreId: curr.GenreId,
            channelsList: [
              {
                ChannelId: curr.ChannelId,
              },
            ],
          });
        }
        return acc;
      }, []);
      const formattedScheduledPublishDt = scheduledPublishDt
        ? new Date(scheduledPublishDt).toISOString()
        : null;
      const genreData = {
        PackageName: PackageName,
        PackageStatus: PackageStatus,
        Description: Description,
        PackageTypeId: getpackageTypeId,
        LanguageId: getLanguageId,
        IsNewUser: IsNewUser,
        IsExistingUser: IsExistingUser,
        newUserPriceDetails: IsNewUser ? newUserPriceDetails : [],
        existingUserPriceDetails: IsExistingUser
          ? existingUserPriceDetails
          : [],
        IsOfferPack: IsOfferPack,
        existingUserOfferPriceDetails: IsOfferPack
          ? 
          Offerpack: [],
        // IncludeNCF: IncludeNCF,
        genreDetails: transformedArray,
        IsPublishLater: isPublishLater,
        ScheduledPublishDt: formattedScheduledPublishDt,
        IsActive: isActive == "Active" ? true : false,
        Comment: comment,
        IsPublished: IsPublished,
        IsApproved: isApproved,
        PublishedOn: publishedon,
        PublishedBy: publishedby,
        ApprovedBy: ApprovedBy,
        ApprovedOn: ApprovedOn,
        Id: editData.Id,
        CreatedBy: editData.CreatedBy,
        CreatedOn: editData.CreatedOn,
        ModifiedBy: userDetails.Id,
        ModifiedOn: getCurrentDateTime(),
        IsDeleted: false,
        IsActive: isActive == "Active" ? true : false,
      };
      // console.log(genreData);
      try {
        const res = await ApiService.put("sdmpackage", genreData);
        if (res) {
          if (res.Error == false) {
            // handleCancel();
            enqueueSnackbar('Added Successfully!',{ variant: 'success', autoHideDuration: 3000 });
          } else {
              enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
          }
          // if (IsPublished) {
          enqueueSnackbar("Updated Successfully!", { variant: "success", autoHideDuration: 3000 });
          setLoading(false);
          onCancel();
          // }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error updating toggle state:", error);
      }
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    await onSubmit();
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved,
      IsPublishedLater: isPublishLater ? isPublishLater : false,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
      const res = await ApiService.put("sdmpackage/approve", publishData);
      // if (res) {
      onCancel();
      enqueueSnackbar("Published & Updated Successfully!", {
        variant: "success", autoHideDuration: 3000
      });
      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setPackageStatus(val);
    if (val != "scheduled") {
      setScheduledPublishDt(null);
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true);
    } else {
      setIsApproved(false);
      setIsPublished(false);
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    onSubmit();
    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const formattedDate = formatDate(editData ? editData.CreatedOn : "");
  const ModifiedformattedDate = formatDate(
    editData ? editData.ModifiedOn : "-"
  );

  const handlePackage = () => {
    if (isNetworkSelected) {
      // console.log("hello");
      setnetworkValue([]);
      setSelectedNetworkChannels({});
    }
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;

    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }

    // Allow only numeric characters and dot
    if (
      (keynum < 48 || keynum > 57) && // numeric characters
      keynum !== 46 &&
      keynum !== 190 // dot characters (190 for keypad dot)
    ) {
      e.preventDefault();
    }
  };
  const [inputGroups, setInputGroups] = useState([
    // {
    //   input1: "",
    //   input2: "",
    //   input3: "",
    //   input4: "",
    //   input5: "",
    //   input6: "",
    //   input7: "",
    //   input8: "",
    //   input9: "",
    // },
  ]);

  const addInputGroup = () => {
    setInputGroups([
      ...inputGroups,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
      },
    ]);
  };

  const removeInputGroup = (index) => {
    const updatedGroups = [...inputGroups];
    updatedGroups.splice(index, 1);
    setInputGroups(updatedGroups);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedGroups = [...inputGroups];
    updatedGroups[index][fieldName] = value;
    setInputGroups(updatedGroups);
  };
  const handleInputBoxChange = (index, fieldName, value) => {
    const selectedType = BoxtypeArray.find(type => type.BoxName === value);
    const updatedGroups = [...inputGroups];
    updatedGroups[index][fieldName] = value;
    updatedGroups[index].input10 = selectedType ? selectedType.Id : null;
    setInputGroups(updatedGroups);
  };
  const isInputGroupValidNew = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== "" 
    );
  };

  const addInputGroupExisting = () => {
    setInputGroupsExisting([
      ...inputGroupExisting,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
      },
    ]);
  };
  const addInputGroupOffer = () => {
    setInputGroupsOffer([
      ...inputGroupOffer,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
      },
    ]);
  };


  const removeInputGroupExisting = (index) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups.splice(index, 1);
    setInputGroupsExisting(updatedGroups);
  };
  const removeInputGroupOffer = (index) => {
    const updatedGroups = [...inputGroupOffer];
    updatedGroups.splice(index, 1);
    setInputGroupsOffer(updatedGroups);
  };

  const handleInputChangeExisting = (index, fieldName, value) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups[index][fieldName] = value;
    setInputGroupsExisting(updatedGroups);
  };
  const handleInputChangeOffer = (index, fieldName, value) => {
    const updatedGroups = [...inputGroupOffer];
    updatedGroups[index][fieldName] = value;
    setInputGroupsOffer(updatedGroups);
  };
  const isInputGroupValid = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== ""
    );
  };
  const isInputGroupValidOffer = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== ""
    );
  };
  const handleChanges = (genreId, selectedChannels, genreName) => {
    setSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]:
        selectedChannels.length > 0
          ? selectedChannels.map((channel) => ({
              ...channel,
              value: channel.ChannelId, // Set the ChannelId as value
              label: channel.ChannelName,
            }))
          : [], // Set an empty array if selectedChannels is empty
    }));
    setSelectedGenre((prevState) => ({
      ...prevState,
      [genreId]: genreName,
    }));
  };
  const InactiveDayshandleChange = (e) => {
    const { name, value } = e.target;
    setDayFrom(
      value === "0-30"
        ? 0
        : value === "31-60"
        ? 31
        : value == "61-90"
        ? 61
        : value == "90+"
        ? 90
        : 0
    ),
      setDayTo(
        value === "0-30"
          ? 30
          : value === "31-60"
          ? 60
          : value == "61-90"
          ? 90
          : value == "90+"
          ? 91
          : 0
      );

  };
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/channellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Package</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Package Name"
                  value={PackageName}
                  onChange={(e) => setPackageName(e.target.value)}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              {languageArray.SdMLanguage &&
                languageArray.SdMLanguage.length > 0 && (


                  <div className="w254">
                  <FormControl>
                    <InputLabel>Language</InputLabel>
                    <Select
                      // value={languagevalue ? languagevalue : 1}
                      value={languagevalue}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        setlanguageValue(newValue);
                      }}
                      required={true}
                      className="commonInput"
                    >
                      {languageArray.SdMLanguage.map((status) => (
                        <MenuItem key={status.Id} value={status.Id}>
                          {status.LanguageName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>


                 
                )}
              {typeArray.SdMPackagetype &&
                typeArray.SdMPackagetype.length > 0 && (
                  <div className="w254">
                    <FormControl>
                      <InputLabel>Package Type</InputLabel>
                      <Select
                        value={PackageTypeValue}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setPackageTypeValue(newValue);
                        }}
                        required={true}
                        className="commonInput"
                      >
                        {typeArray.SdMPackagetype.filter(
                          (option) =>
                            option.PackageType !== "Add-Ons" &&
                            option.PackageType !== "Network"
                        ).map((status) => (
                          <MenuItem key={status.Id} value={status.Id}>
                            {status.PackageType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
            </div>
            <div className="example-container m-b">
              <MaterialUITextField
                className="commonInput multiSelectWidth"
                label="Package Description"
                name="Description"
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                maxLength={100}
                rows={4}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers, <Space> & - and + upto 100 chars."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>

            <div className="exmple-container m-b">
              <div className=" m-24">
                <p className="post">User Type</p>
                {!isAddonsSelected && (
                  <span>
                    <Checkbox
                      checked={IsNewUser}
                      onChange={(e) => setIsNewUser(e.target.checked)} // Use e.target.checked to set the new value
                      sx={{
                        color: "#EE3823",
                        "&.Mui-checked": {
                          color: "#EE3823",
                        },
                      }}
                    />
                    New
                  </span>
                )}
                <Checkbox
                  checked={IsExistingUser}
                  name="IsExistingUser"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setIsExistingUser(isChecked);
                    setIsOfferPack(!isChecked ? false : true);
                  }}
                  sx={{
                    color: "#EE3823",
                    "&.Mui-checked": {
                      color: "#EE3823",
                    },
                  }}
                />{" "}
                Existing
                <Checkbox
                  checked={!IsExistingUser ? false : IsOfferPack}
                  name="IsOfferPack"
                  onChange={(e) => setIsOfferPack(e.target.checked)}
                  disabled={!IsExistingUser}
                  sx={{
                    color: "#EE3823",
                    "&.Mui-checked": {
                      color: "#EE3823",
                    },
                  }}
                />{" "}
                Offer Pack
                {/* Validation error message */}
                {checkboxVali && !IsExistingUser && !IsNewUser && (
                  <FormHelperText error>
                    Please select at least one option.
                  </FormHelperText>
                )}
              </div>
            </div>
            
            {/* Conditional rendering of input fields */}
            {IsNewUser && (
              <div>
                {/* <div className="example-container m-b">
                  <MaterialUITextField
                    label="New User Monthly Price"
                    name="NewUserMonthlyPrice"
                    value={NewUserMonthlyPrice}
                    onChange={(e) => setNewUserMonthlyPrice(e.target.value)}
                    onKeyDown={handleKeyDown}
                    maxLength={4}
                    className="commonInput"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Supports numbers up to 25 chars.">
                          <InfoIcon className="Icon-style" />
                        </Tooltip>
                      ),
                    }}
                  />
                </div> */}
                <div className="example-container m-b">
                  <p className="post">New User Price List</p>
                  <div>
                    {inputGroups.map((group, index) => (
                      <div>
                        <div key={index} className="input-group">
                          <FormControl>
                            <InputLabel>Box Type</InputLabel>
                            <Select
                              name="Box Type" 
                              value={group.input9}
                              onChange={(e) =>
                                handleInputBoxChange(
                                  index,
                                  "input9",
                                  e.target.value
                                )
                              }
                              variant="standard"
                              className="commonInput m-48 m-b"
                            >
                              {BoxtypeArray !== null ? (
                                BoxtypeArray.map((type) => (
                                  <MenuItem key={type.Id} value={type.BoxName}>
                                    {type.BoxName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="">Loading...</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          <MaterialUITextField
                            label="Description"
                            value={group.input1}
                            onChange={(e) =>
                              handleInputChange(index, "input1", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="Price"
                            value={group.input2}
                            onChange={(e) =>
                              handleInputChange(index, "input2", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="Total GST Price"
                            value={group.input3}
                            onChange={(e) =>
                              handleInputChange(index, "input3", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="NCF Price"
                            value={group.input4}
                            onChange={(e) =>
                              handleInputChange(index, "input4", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="GST Price"
                            value={group.input5}
                            onChange={(e) =>
                              handleInputChange(index, "input5", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="Exclude NCF Price"
                            value={group.input6}
                            onChange={(e) =>
                              handleInputChange(index, "input6", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="System Name"
                            value={group.input11}
                            onChange={(e) =>
                              handleInputChange(index, "input11", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                            required
                          />
                          <MaterialUITextField
                            label="POID"
                            value={group.input8}
                            onChange={(e) =>
                              handleInputChange(index, "input8", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="IsActive"
                              value={group.input7}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "input7",
                                  e.target.value
                                )
                              }
                              className="commonInput m-48 m-b"
                            >
                              {statusOptions.map((status) => (
                                <MenuItem
                                  key={status.value}
                                  value={status.value}
                                >
                                  {status.viewValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            color="secondary"
                            onClick={() => removeInputGroup(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <Divider className="lineb" />
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addInputGroup}
                    disabled={
                      inputGroups.length === 0
                        ? false
                        : !isInputGroupValidNew(
                            inputGroups[inputGroups.length - 1]
                          ) ||
                          inputGroups.some(
                            (group) => !isInputGroupValidNew(group)
                          )
                    }
                    className={`btn ${
                      inputGroups.length === 0
                        ? false
                        : !isInputGroupValidNew(
                            inputGroups[inputGroups.length - 1]
                          ) ||
                          inputGroups.some(
                            (group) => !isInputGroupValidNew(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}

            {IsExistingUser && (
              <div>
                <div className="example-container m-b">
                  <p className="post">Existing User Price List</p>
                  <div>
                    {inputGroupExisting.map((group, index) => (
                      <div>
                        <div key={index} className="input-group">
                          {/* <FormControl>
                            <InputLabel>Box Type</InputLabel>
                            <Select
                              name="Box Type"
                              value={group.input9}
                              onChange={(e) =>
                                handleInputChangeExisting(
                                  index,
                                  "input9",
                                  e.target.value
                                )
                              }
                              variant="standard"
                              className="commonInput m-48 m-b"
                              required
                            >
                              {BoxtypeArray !== null ? (
                                BoxtypeArray.map((type) => (
                                  <MenuItem key={type.Id} value={type.Id}>
                                    {type.BoxType}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="">Loading...</MenuItem>
                              )}
                            </Select>
                          </FormControl> */}
                          <MaterialUITextField
                            label="Description"
                            value={group.input1}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input1",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                            required
                          />
                          <MaterialUITextField
                            label="Price"
                            value={group.input2}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input2",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="Total GST Price"
                            value={group.input3}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input3",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="NCF Price"
                            value={group.input4}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input4",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="GST Price"
                            value={group.input5}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input5",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                          label="System Name"
                          value={group.input9}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input9",
                              e.target.value
                            )
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                          <MaterialUITextField
                            label="Exclude NCF Price"
                            value={group.input6}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input6",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="POID"
                            value={group.input7}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input7",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="IsActive"
                              value={group.input8}
                              onChange={(e) =>
                                handleInputChangeExisting(
                                  index,
                                  "input8",
                                  e.target.value
                                )
                              }
                              className="commonInput m-48 m-b"
                            >
                              {statusOptions.map((status) => (
                                <MenuItem
                                  key={status.value}
                                  value={status.value}
                                >
                                  {status.viewValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            color="secondary"
                            onClick={() => removeInputGroupExisting(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <Divider className="lineb" />
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={addInputGroupExisting}
                    disabled={
                      inputGroupExisting.length === 0
                        ? false
                        : !isInputGroupValid(
                            inputGroupExisting[inputGroupExisting.length - 1]
                          ) ||
                          inputGroupExisting.some(
                            (group) => !isInputGroupValid(group)
                          )
                    }
                    className={`btn ${
                      inputGroupExisting.length === 0
                        ? false
                        : !isInputGroupValid(
                            inputGroupExisting[inputGroupExisting.length - 1]
                          ) ||
                          inputGroupExisting.some(
                            (group) => !isInputGroupValid(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
             {(IsExistingUser && IsOfferPack) && (
              <div>
                <div className="example-container m-b">
                  <p className="post">Offer Pack Price List</p>
                  <div>
                    {inputGroupOffer.map((group, index) => (
                      <div>
                        <div key={index} className="input-group">
                        <FormControl>
                      <InputLabel>Inactive Days</InputLabel>
                      <Select
                        name="inactiveDays"
                        value={group.input6}
                        onChange={(e) =>
                          handleInputChangeOffer(
                            index,
                            "input6",
                            e.target.value
                          )
                        }
                        required
                        className="commonInput m-48 m-b"
                      >
                        { OffersDays &&OffersDays.map((status) => (
                          <MenuItem key={status.value} value={status.Id}>
                            {status.ExpiryDays}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                          <MaterialUITextField
                            label="Description"
                            value={group.input1}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input1",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                            required
                          />
                          <MaterialUITextField
                            label="Price"
                            value={group.input2}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input2",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="Total GST Price"
                            value={group.input3}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input3",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="NCF Price"
                            value={group.input4}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input4",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="GST Price"
                            value={group.input5}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input5",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <MaterialUITextField
                            label="System Name"
                            value={group.input9}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input9",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                            required
                          />
                          <MaterialUITextField
                            label="POID"
                            value={group.input7}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input7",
                                e.target.value
                              )
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          />
                          <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="IsActive"
                              value={group.input8}
                              onChange={(e) =>
                                handleInputChangeOffer(
                                  index,
                                  "input8",
                                  e.target.value
                                )
                              }
                              className="commonInput m-48 m-b"
                            >
                              {statusOptions.map((status) => (
                                <MenuItem
                                  key={status.value}
                                  value={status.value}
                                >
                                  {status.viewValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            color="secondary"
                            onClick={() => removeInputGroupOffer(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <Divider className="lineb" />
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addInputGroupOffer}
                    disabled={
                      inputGroupOffer.length === 0
                        ? false
                        : !isInputGroupValidOffer(
                            inputGroupOffer[inputGroupOffer.length - 1]
                          ) ||
                          inputGroupOffer.some(
                            (group) => !isInputGroupValidOffer(group)
                          )
                    }
                    className={`btn ${
                      inputGroupOffer.length === 0
                        ? false
                        : !isInputGroupValidOffer(
                            inputGroupOffer[inputGroupOffer.length - 1]
                          ) ||
                          inputGroupOffer.some(
                            (group) => !isInputGroupValidOffer(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
            {!isNetworkSelected && ChannelArray && ChannelArray.length > 0 && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={Channelvalue}
                    onChange={(event, newValue) => {
                      setChannelValue(newValue);
                      // console.log(Channelvalue);
                    }}
                    options={ChannelArray}
                    getOptionLabel={(option) => (option.ChannelName+"("+option.GenreName+")")}
                    getOptionDisabled={(option) =>
                      Channelvalue.some(
                        (selected) => selected.ChannelId === option.ChannelId && selected.GenreId === option.GenreId
                      )
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          label={(option.ChannelName+"("+option.GenreName+")")}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={"Channels ("+ Channelvalue.length+ ")"}
                        placeholder="Channels"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="displayFlex m-b">
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {userDetails &&
                (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                  <div className="displayFlex">
                    <div className="example-container">
                      <MaterialUITextField
                        className="commonInput"
                        label="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                    <div className="example-container">
                      <FormControl>
                        <InputLabel>Approval Status</InputLabel>
                        <Select
                          className="commonInput"
                          value={PackageStatus}
                          onChange={(e) =>
                            onApprovalStatusChange(e.target.value)
                          }
                          required
                        >
                          {ApproveStatus.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.viewValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <p className="imgInfo">[Select Approval to publish]</p>
                    </div>
                  </div>
                )}
            </div>

            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex">
                  {PackageStatus === "scheduled" && (
                    <div className="example-container">
                      <MaterialUITextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={
                          scheduledPublishDt
                            ? scheduledPublishDt.split("T")[0]
                            : scheduledPublishDt
                        }
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ""} By{" "}
                  {editData ? editData.CreatedByName : ""}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ""} By{" "}
                  {editData ? editData.ModifiedByName : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="submitButton">
            {PackageStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {PackageStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}

            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PackageEdit;
