import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ApiService from "../../../Services/ApiService";
import { Loader } from "../../Loader";
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
const NetworkEdit = ({ onCancel, dataSource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef(null);
  const [loading, setLoading] = useState();
  const [ChannelArray, setChannel] = useState([]);
  const [Channelvalue, setChannelValue] = React.useState([]);
  const [NetworkName, setNetworkName] = useState("");
  const [isActive, setIsActive] = useState("Active");
  const [NetworkStatus, setNetworkStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState();
  const [isApproved, setIsApproved] = useState();
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();
  const [CreateById, setCreatedById] = useState();
  const [userDetails, setUserDetails] = useState(null);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ApprovedBy, setApprovedBy] = useState();
  const [ApprovedOn, setApprovedOn] = useState();
  const [IsPublished, setIsPublished] = useState(true);
  const [postedName, setPostedName] = useState();
  const [ListArray, setListArray] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  useEffect(() => {
    // getEditDetails(rowData);
    getGenreList();
    getChannelList();
    getData();
    setUserDetails(JSON.parse(localStorage.getItem("userDetail")));
  }, [dataSource]);
  const [editData, setEditData] = useState(); // Add this line
  const getGenreList = async () => {
    try {
      const res = await ApiService.getAll("sdmnetwork/genrewithchannels");
      if (res) {
        // console.log(res);
        setListArray(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {
    // This effect runs whenever editData changes
    // console.log("Edit Data:", editData);
    const initialSelectedChannels = {};
    if (editData) {
      // Access properties of editData safely
      editData.NetworkGenres.forEach((genre) => {
        initialSelectedChannels[genre.GenreId] = genre.NetworkGenreChannel.map(
          (channel) => ({
            ...channel,
            label: channel.ChannelName,
            Id: channel.ChannelId,
          })
        );
      });
      if (editData) {
        const transformedDatas = editData.NetworkGenres.flatMap(
          ({ GenreId, GenreName, NetworkGenreChannel }) =>
            NetworkGenreChannel.map(({ ChannelId, ChannelName }) => ({
              ChannelId,
              GenreId,
              GenreName,
              ChannelName,
            }))
        );
        setChannelValue(transformedDatas);
      }
      // console.log(initialSelectedChannels);
      setSelectedChannels(initialSelectedChannels);
    }
  }, [editData]); // Add editData as dependency
  const getData = async () => {
    try {
      const res = await ApiService.getOne("sdmnetwork", dataSource);
      if (res) {
        setNetworkName(res.NetworkName);
        setNetworkStatus(
          res.NetworkStatus == "Published" ? "approval" : res.NetworkStatus
        );
        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater(res.IsPublishLater);
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setApprovedBy(res.ApprovedBy);
        setApprovedOn(res.ApprovedOn);
        setCreatedById(res.CreatedBy);
        setIsPublished(res.IsPublished);
        getUserName(res.CreatedBy);
        setEditData(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  const getUserName = async (id) => {
    try {
      const res = await ApiService.getOne("sdmusers", id);
      if (res) {
        // console.log(res);
        setPostedName(res.FirstName);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleApprovePublish = (e) => {
    e.preventDefault();
    if(NetworkStatus != "approval"){
      onSubmit();
    }
  };
  useEffect(() => {}, [editData]);
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/channellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const combined = [];
    Object.entries(selectedChannels).forEach(([genreId, channels]) => {
      const genreName = selectedGenre[genreId];
      const networkGenre = {
        GenreId: parseInt(genreId),
        GenreName: genreName,
        NetworkGenreChannel: channels.map((channel) => ({
          ChannelId: channel.Id,
          ChannelName: channel.ChannelName,
        })),
      };
      combined.push(networkGenre);
    });
    const convertedData = Object.values(Channelvalue.reduce((acc, { ChannelId, GenreId, GenreName, ChannelName }) => {
      if (!acc[GenreId]) {
          acc[GenreId] = {
              GenreId,
              GenreName,
              NetworkGenreChannel: []
          };
      }
      acc[GenreId].NetworkGenreChannel.push({ ChannelId, ChannelName });
      return acc;
  }, {}));
    const NetworkData = {
      NetworkName: NetworkName,
      NetworkStatus: NetworkStatus,
      IsPublishLater: isPublishLater,
      ScheduledPublishDt: formattedScheduledPublishDt,
      IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      IsPublished: IsPublished,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),
      ApprovedBy: ApprovedBy,
      ApprovedOn: ApprovedOn,
      NetworkGenres: convertedData,
    };
    // console.log(NetworkData);
    try {
      const res = await ApiService.put("sdmnetwork", NetworkData);
      if (res) {
        // if (IsPublished) {
          // enqueueSnackbar("Updated Successfully!", { variant: "success", autoHideDuration: 3000 });
          // onCancel();
        // }
        setLoading(false);
        if (res.Error == false) {
          enqueueSnackbar("Updated Successfully!", { variant: "success", autoHideDuration: 3000 });
          onCancel();
        } else {
            enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating toggle state:", error);
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    await onSubmit();
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved ? isApproved : false,
      IsPublishedLater: isPublishLater ? isPublishLater : false,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
      const res = await ApiService.put("sdmnetwork/approve", publishData);
      // if (res) {
      onCancel();
      enqueueSnackbar("Published Successfully!", {
        variant: "success", autoHideDuration: 3000
      });
      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setNetworkStatus(val);
    if (val != "scheduled") {
      setScheduledPublishDt(null);
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true);
    } else {
      setIsApproved(false);
      setIsPublished(false);
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    onSubmit();
    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleChanges = (genreId, selectedChannels, genreName) => {
    setSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]:
        selectedChannels.length > 0
          ? selectedChannels.map((channel) => ({
              ...channel,
              value: channel.ChannelId, // Set the ChannelId as value
              label: channel.ChannelName,
            }))
          : [], // Set an empty array if selectedChannels is empty
    }));
    setSelectedGenre((prevState) => ({
      ...prevState,
      [genreId]: genreName,
    }));
    // console.log(selectedChannels);
  };
  const formattedDate = formatDate(editData ? editData.CreatedOn : "");
  const ModifiedformattedDate = formatDate(
    editData ? editData.ModifiedOn : "-"
  );
  return (
    <div>
      {loading && <Loader />}
      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Network</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Network"
                  value={NetworkName}
                  onChange={(e) => setNetworkName(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="exmple-container m-b">
              {/* <div className="commonInput m-24">
    <p className="post">Genre</p>
    {ListArray
      ? ListArray.map((genre) => (
        <Autocomplete
        key={genre.Id}
        multiple
        id={`autocomplete-${genre.Id}`}
        value={selectedChannels[genre.Id] || []}
        onChange={(event, value) => handleChanges(genre.Id, value, genre.GenreName)}
        options={genre.channelList.filter(option => !(selectedChannels[genre.Id] && selectedChannels[genre.Id].find(channel => channel.Id === option.Id)))}
        getOptionLabel={(option) => option.ChannelName}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.label || option.ChannelName} // Use option.label if available
              {...getTagProps({ index })}
              className="chipsStyle"
            />
          ))
        }
        style={{ width: 858, marginBottom: 24 }}
        renderInput={(params) => (
          <MuiTextField {...params} label={genre.GenreName} variant='standard' />
        )}
      />
        ))
      : null}
  </div> */}
              {ChannelArray && ChannelArray.length > 0 && (
                <div className="exmple-container m-b">
                  <div className="commonInput">
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo3"
                      value={Channelvalue}
                      onChange={(event, newValue) => {
                        setChannelValue(newValue);
                        // console.log(Channelvalue);
                      }}
                      options={ChannelArray}
                      getOptionLabel={(option) => (option.ChannelName+"("+option.GenreName+")")}
                      getOptionDisabled={(option) =>
                        Channelvalue.some(
                          (selected) => selected.ChannelId === option.ChannelId && selected.GenreId === option.GenreId
                        )
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={(option.ChannelName+"("+option.GenreName+")")}
                            {...getTagProps({ index })}
                            className="chipsStyle"
                          />
                        ))
                      }
                      style={{ width: 858, marginBottom: 24 }}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={"Channels (" + Channelvalue.length +")"}
                          placeholder="Channels"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>

            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex">
                  <div className="example-container">
                    <FormControl>
                      <InputLabel>Approval Status</InputLabel>
                      <Select
                        className="commonInput"
                        value={NetworkStatus}
                        onChange={(e) => onApprovalStatusChange(e.target.value)}
                        required
                      >
                        {ApproveStatus.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.viewValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <p className="imgInfo">[Select Approval to publish]</p>
                  </div>
                  <div className="example-container">
                    <MaterialUITextField
                      className="commonInput"
                      label="Comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  {NetworkStatus === "scheduled" && (
                    <div className="example-container">
                      <MaterialUITextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={
                          scheduledPublishDt
                            ? scheduledPublishDt.split("T")[0]
                            : scheduledPublishDt
                        }
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ""} By{" "}
                  {editData ? editData.CreatedByName : ""}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ""} By{" "}
                  {editData ? editData.ModifiedByName : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="submitButton">
          {NetworkStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {NetworkStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}
            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NetworkEdit;
